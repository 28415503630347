import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div>
            <h1 className="text-4xl font-bold mb-6 h-[50vh] text-white flex items-center justify-center bg-purple-800">Privacy Policy</h1>
            <div className="container mx-auto p-8">

                <p className="mb-4">
                    At Godigitify Nexus, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and disclose information about you when you use our services, including our website and any other online or offline offerings (collectively, the "Services").
                </p>

                <h2 className="text-2xl font-semibold mb-4">Information We Collect</h2>
                <p className="mb-4">
                    We collect information about you in various ways when you use our Services. This includes:
                </p>
                <ul className="list-disc list-inside mb-4">
                    <li>
                        <strong>Information You Provide:</strong> We collect information you provide directly to us, such as when you create an account, update your profile, use interactive features of our Services, or communicate with us.
                    </li>
                    <li>
                        <strong>Information We Collect Automatically:</strong> We automatically collect certain information about you when you access or use our Services. This may include your IP address, browser type, operating system, referral URLs, device information, and other usage data.
                    </li>
                    <li>
                        <strong>Information from Third Parties:</strong> We may receive information about you from third parties, such as social media platforms, partners, and analytics providers.
                    </li>
                </ul>

                <h2 className="text-2xl font-semibold mb-4">How We Use Your Information</h2>
                <p className="mb-4">
                    We use the information we collect to:
                </p>
                <ul className="list-disc list-inside mb-4">
                    <li>Provide, maintain, and improve our Services;</li>
                    <li>Personalize your experience on our Services;</li>
                    <li>Respond to your comments, questions, and requests;</li>
                    <li>Send you technical notices, updates, security alerts, and support messages;</li>
                    <li>Monitor and analyze trends, usage, and activities in connection with our Services;</li>
                    <li>Detect, investigate, and prevent fraudulent transactions and other illegal activities;</li>
                    <li>Comply with legal obligations;</li>
                    <li>Protect the rights and property of Godigitify Nexus and our users.</li>
                </ul>

                <h2 className="text-2xl font-semibold mb-4">How We Share Your Information</h2>
                <p className="mb-4">
                    We may share information about you as follows:
                </p>
                <ul className="list-disc list-inside mb-4">
                    <li>With your consent or at your direction;</li>
                    <li>With vendors, consultants, and other service providers who need access to such information to carry out work on our behalf;</li>
                    <li>In response to a request for information if we believe disclosure is in accordance with any applicable law, regulation, or legal process;</li>
                    <li>If we believe your actions are inconsistent with our user agreements or policies, or to protect the rights, property, and safety of Godigitify Nexus or others;</li>
                    <li>In connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business by another company;</li>
                    <li>With third parties for the purpose of providing advertising, analytics, and other services.</li>
                </ul>

                <h2 className="text-2xl font-semibold mb-4">Your Choices</h2>
                <p className="mb-4">
                    You have certain choices regarding your information:
                </p>
                <ul className="list-disc list-inside mb-4">
                    <li>You can update or correct information about yourself by logging into your account or contacting us;</li>
                    <li>You can opt out of receiving promotional emails from us by following the instructions in those emails;</li>
                    <li>Most web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove or reject browser cookies;</li>
                    <li>If you delete or disable cookies, some features of our Services may not work properly.</li>
                </ul>

                <h2 className="text-2xl font-semibold mb-4">Security</h2>
                <p className="mb-4">
                    We take reasonable measures to help protect information about you from loss, theft, misuse, and unauthorized access, disclosure, alteration, and destruction.
                </p>

                <h2 className="text-2xl font-semibold mb-4">Changes to this Privacy Policy</h2>
                <p className="mb-4">
                    We may update this Privacy Policy from time to time. If we make changes, we will notify you by revising the date at the top of the policy and, in some cases, we may provide additional notice (such as adding a statement to our homepage or sending you a notification).
                </p>

                <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
                <p>
                    If you have any questions about this Privacy Policy, please contact us at <a href="mailto:privacy@godigitify.com" className="text-blue-500">privacy@godigitify.com</a>.
                </p>
            </div>
        </div>
    );
}

export default PrivacyPolicy;
