import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';

function ContactForm() {
  return (
    <section className="text-gray-600 body-font md:px-20 px-5 lg:px-40 border-b border-gray-200">
      <div className="container px-5 pt-24">
        <div className="flex flex-col  w-full lg:w-2/3">
          <div className="flex">
            <h2 className="text-lg text-[#4e286e] tracking-widest font-medium title-font mb-1">
              Contact to connect
            </h2>{' '}
            <div className="lg:ml-3 invisible lg:visible bg-[#4e286e] mt-3 h-[2px] lg:w-[200px]"></div>
          </div>
          <h1 className="sm:text-5xl mt-2 text-2xl leading-9  title-font text-gray-900">
            Don't hesitate to inquire about a one-stop solution.
          </h1>
        </div>
        <div className="text-gray-600 body-font">
          <div className="container flex flex-wrap py-20 mx-auto items-center">
            <div className="md:w-1/2 md:pr-12 md:py-8 md:border-r md:border-b-0 mb-10 md:mb-0 pb-10 border-b border-gray-200">
              <p className="leading-relaxed text-base">
                Join us on our mission to help the healthcare and education sectors develop their
                digital landscape and increase revenue.
              </p>
              <p className="leading-relaxed text-base mt-8">
                Contact us through phone, email, Linkedin message, Tweet, or meta message. Or just
                stop by our workspace; we’d be happy to sit down and chat as you enjoy a black
                coffee or Kadak chai.
              </p>
            </div>
            <div className="flex flex-wrap flex-row md:w-1/2 md:pl-12 text-center">
              <div className="w-full md:w-1/3 px-3 flex flex-col my-3 ">
                <FontAwesomeIcon icon={faMapMarkerAlt} className="text-4xl   mb-2" />
                <h4 className="sm:text-center text-md text-black font-medium">Address:</h4>
                <p className="text-sm">
                   Chandigarh, India.
                </p>
              </div>
              <div className="w-full md:w-1/3 px-3 flex flex-col my-3 ">
                <FontAwesomeIcon icon={faPhone} className="text-4xl mb-2" />
                <h4 className="sm:text-center text-md text-black font-medium">Contact:</h4>
                <p className="text-sm">
                 7837300479
                </p>
              </div>
              <div className="w-full md:w-1/3 px-3 flex flex-col my-3 ">
                <FontAwesomeIcon icon={faEnvelope} className="text-4xl mb-2  " />
                <h4 className="sm:text-center text-md text-black font-medium">E-mail:</h4>
                <p className="text-sm">contact@godigitify.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    
  );
}

export default ContactForm;
