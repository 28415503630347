import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './keyfeatures.css';
import { MdAndroid, MdCloud, MdBuild, MdStorage } from 'react-icons/md'; // Import Material Icons
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

const keyFeaturesData = [
  {
    icon: <MdAndroid />,
    title: 'Android Development',
    description: 'Craft dynamic and user-friendly Android applications tailored to your business needs. Harness the power of cutting-edge technologies to create immersive experiences on the world’s most popular mobile platform.',
    link: '/services',
  },
  {
    icon: <MdCloud />,
    title: 'Cloud Computing',
    description: 'Leverage the scalability, reliability, and flexibility of cloud computing solutions to transform your business operations. Harness the power of leading cloud platforms to drive innovation and growth.',
    link: '/services',
  },
  {
    icon: <MdBuild />,
    title: 'DevOps Solutions',
    description: 'Accelerate your software development lifecycle and improve collaboration between development and operations teams with robust DevOps solutions. Streamline processes, automate deployments, and enhance agility.',
    link: '/services',
  },
  {
    icon: <MdStorage />,
    title: 'Database Management',
    description: 'Optimize your database infrastructure for performance, reliability, and security. From data modeling to optimization, ensure efficient data management and unlock valuable insights for your business.',
    link: '/services',
  },
];

const KeyFeatures = () => {


  const handleClick = () => {
    // Scroll smoothly to the top of the page
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };


  const sliderRef = useRef(null);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="bg-white py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <p className="mt-2 text-3xl leading-8 font-medium tracking-tight text-gray-900 sm:text-4xl">Our <span className='text-purple-800'> Key Features</span></p>
          {/* <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">Lets hear it from some of the eminent personalities</p> */}
        </div>
        <div className="mt-10 relative">
          <Slider {...settings}>
            {keyFeaturesData.map((testimonial, index) => (
              <TestimonialCard
                key={index}
                name={testimonial.title}
                position={testimonial.position}
                text={testimonial.description}
                icon={testimonial.icon}
              // orgImage={testimonial.organizationImage}
              />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

const NextArrow = ({ onClick }) => (
  <div className="slick-arrow absolute bottom-0 left-1/2 transform -translate-x-1/2 bg-white p-2 rounded-full shadow-md cursor-pointer">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="#7F1DFF"
      className="h-6 w-6"
      onClick={onClick}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M9 5l7 7-7 7"
      />
    </svg>
  </div>
);

const PrevArrow = ({ onClick }) => (
  <div className="slick-arrow absolute bottom-0 left-1/2 transform -translate-x-1/2 bg-white p-2 rounded-full shadow-md cursor-pointer">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="#7F1DFF"
      className="h-6 w-6 transform rotate-180"
      onClick={onClick}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M9 5l7 7-7 7"
      />
    </svg>
  </div>
);

const TestimonialCard = ({ name, position, text, icon, orgImage }) => {
  return (
    <div className="bg-white overflow-hidden shadow rounded-lg">
      <div className="px-4 py-5 sm:p-6 h-[400px] flex flex-col justify-between">
        <div className="flex items-center">
          <div className="flex-shrink-0 bg-purple-800 text-white h-10 w-10 rounded-full text-2xl flex justify-center items-center">
            {icon}
          </div>

        </div>
        <div className="mt-4">
          <div className="mb-4">
            {/* <p className="text-lg font-bold text-gray-900">{name}</p> */}
            <p className="text-2xl text-purple-800">{name}</p>
          </div>
          <p className="text-base text-gray-700">{text}</p>
        </div>
        <div className='flex justify-end'>
          <img src={orgImage} className='right-0 h-[35px]' alt="" srcset="" />
        </div>
      </div>
    </div>
  );
};

export default KeyFeatures;
