import React from 'react';

const TermsAndConditions = () => {
    return (
        <div>
            <h1 className="text-4xl font-bold mb-6 h-[50vh] text-white flex items-center justify-center bg-purple-800">Terms and Conditions</h1>
            <div className="container mx-auto p-8">

                <p className="mb-4">
                    Welcome to Godigitify Nexus. These terms and conditions outline the rules and regulations for the use of our website and services. By accessing or using our services, you agree to comply with and be bound by these terms and conditions. If you do not agree with any part of these terms and conditions, you must not use our services.
                </p>

                <h2 className="text-2xl font-semibold mb-4">Use of Our Services</h2>
                <p className="mb-4">
                    You agree to use our services only for lawful purposes and in accordance with these terms and conditions. You must not use our services in any way that breaches any applicable local, national, or international law or regulation.
                </p>

                <h2 className="text-2xl font-semibold mb-4">Intellectual Property</h2>
                <p className="mb-4">
                    Unless otherwise stated, Godigitify Nexus owns the intellectual property rights for all material on our website and services. All intellectual property rights are reserved. You may access this material for your own personal use, subject to restrictions set in these terms and conditions.
                </p>
                <ul className="list-disc list-inside mb-4">
                    <li>You must not republish material from our services without prior written consent.</li>
                    <li>You must not sell, rent, or sub-license material from our services.</li>
                    <li>You must not reproduce, duplicate, or copy material from our services.</li>
                    <li>You must not redistribute content from our services unless content is specifically made for redistribution.</li>
                </ul>

                <h2 className="text-2xl font-semibold mb-4">User Accounts</h2>
                <p className="mb-4">
                    When you create an account with us, you must provide us with accurate and complete information. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer or device. You agree to accept responsibility for all activities that occur under your account or password.
                </p>

                <h2 className="text-2xl font-semibold mb-4">Termination</h2>
                <p className="mb-4">
                    We may terminate or suspend your account and access to our services immediately, without prior notice or liability, if you breach these terms and conditions. Upon termination, your right to use our services will cease immediately.
                </p>

                <h2 className="text-2xl font-semibold mb-4">Limitation of Liability</h2>
                <p className="mb-4">
                    In no event shall Godigitify Nexus, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your use or inability to use our services; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein; (iii) any interruption or cessation of transmission to or from our services; (iv) any bugs, viruses, trojan horses, or the like that may be transmitted to or through our services by any third party; (v) any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through our services; and/or (vi) the defamatory, offensive, or illegal conduct of any third party.
                </p>

                <h2 className="text-2xl font-semibold mb-4">Governing Law</h2>
                <p className="mb-4">
                    These terms and conditions shall be governed and construed in accordance with the laws of [Your Country/State], without regard to its conflict of law provisions.
                </p>

                <h2 className="text-2xl font-semibold mb-4">Changes to Terms and Conditions</h2>
                <p className="mb-4">
                    We reserve the right, at our sole discretion, to modify or replace these terms and conditions at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
                </p>

                <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
                <p>
                    If you have any questions about these terms and conditions, please contact us at <a href="mailto:info@godigitify.com" className="text-blue-500">info@godigitify.com</a>.
                </p>
            </div>
        </div>
    );
}

export default TermsAndConditions;
