import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faShare } from '@fortawesome/free-solid-svg-icons';
import {
    EmailIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    FacebookShareButton,
    GabIcon,
    HatenaIcon,
    InstapaperIcon,
    LineIcon,
    LineShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    LivejournalIcon,
    MailruIcon,
    OKIcon,
    PinterestIcon,
    PocketIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    ViberIcon,
    VKIcon,
    WeiboIcon,
    WhatsappIcon,
    WorkplaceIcon,
    XIcon,
} from "react-share";
const DemoBlog = ({blogs}) => {
    const handleClick = () => {
        // Scroll smoothly to the top of the page
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    // Sample data for three blog cards
    const blogData = blogs.slice(-3)
    const shareUrl = 'https://www.godigitify.com/blogs  '
    return (
        <div className='py-10 h-auto lg:h-[100vh] flex flex-col justify-center'>
            <div className="w:full lg:w-4/5 mx-auto">
                <p className="section-title text-4xl">Unveiling the Tech Odyssey</p>

                <div className="flex flex-wrap mt-8">
                    {blogData.map((blog, index) => (
                        <div key={index} className="w-full md:w-1/2 lg:w-1/3 h-[100%] p-4">
                            <div
                                className="bg-cover bg-center h-64"
                                style={{ backgroundImage: `url(${blog.imageUrl})` }}
                            ></div>
                            <div className="bg-white p-4 mt-4" style={{ borderLeft: "1px solid #00112d50" }}>
                                <h2 className="text-l font-semibold">{blog.title}</h2>
                                <p className="text-sm line-clamp-2">{blog.description}</p>
                                <div className='mt-4'>
                                    <Link onClick={handleClick} to={`/blogs/`} className="mt-2 dark-color-bg text-white hover:bg-red-900  py-1 px-4 rounded-md text-sm transition-transform duration-300 transform hover:translate-x-2">
                                        Read More <FontAwesomeIcon icon={faArrowRight} />
                                    </Link>
                                </div>
                                <div className='flex justify-between mt-5'>
                                    <p className='text-md font-medium'>{blog.authorName}</p>
                                    <div className='flex gap-2'>
                                        <p className='text-[#00112d]'>
                                            <a href={blog.authorLinkedin}> <FontAwesomeIcon icon={faLinkedin} /></a>

                                        </p>
                                        <LinkedinShareButton url={shareUrl} formTarget='_black'>
                                            <FontAwesomeIcon icon={faShare} />
                                            {/* <LinkedinIcon size={32} round={true}/> */}
                                        </LinkedinShareButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="flex justify-end">
                    <Link onClick={handleClick} to="/blogs" style={{ textDecoration: "none" }}>
                        Read More <FontAwesomeIcon icon={faArrowRight} />
                    </Link>

                </div>
            </div>
        </div>
    );
};

export default DemoBlog;
