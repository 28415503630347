// import Navbar from '../src/layout/Navbar'
import Footer from './layout/Footer'
import './App.css';
import RouterOutlet from './RouterOutlet';
import MegaMenuWithHover from './layout/MegaMenuWithHover';
import { Routes, Route, Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';




import About from "./Views/About";
import Services from "./Views/Services";
import Contact from "./Views/Contact";
import Events from "./Views/Events";
import Blogs from "./Views/Blogs";
import Home from "./Views/Home";
import SubBlog from "./Views/servicesub/Sbs";
import BlopPage from "./components/Blog/BlopPage";
import CareersHome from "./components/LandingPageComponents/Careers/CarrersHome";
import Sbs from "./Views/servicesub/Sbs";
import Bs from "./Views/servicesub/Bs";
import Web from "./Views/servicesub/Web";
import Softdev from "./Views/servicesub/Softdev";
import MobileAppdev from "./Views/servicesub/MobileAppdev";
import EnterpriseSolutions from "./Views/servicesub/EnterpriseSolutions";
import Cloudsolutions from "./Views/servicesub/Cloudsolutions";
import Graphicdesign from "./Views/servicesub/Graphicdesign";
import Logodesign from "./Views/servicesub/Logodesign";
import Digitaldesign from "./Views/servicesub/Digitaldesign";
import Campaignmanagement from "./Views/servicesub/Campaignmanagement";
import Onlinereputation from "./Views/servicesub/Onlinereputation";
import SocialMedia from "./Views/servicesub/SocialMedia";
import Ecommerce from "./Views/servicesub/Ecommerce";
import MainLogin from "./Login/MainLogin";
import Projects from "./Views/Projects";
import Create from "./Views/Create";
import Admin from "./Views/Admin";
import Nexus from './Views/Nexus';
import PrivacyPolicy from './Views/PrivacyPolicy';
import TermsAndConditions from './Views/TermsAndConditions';
// import axios from 'axios';
function App() {

  const [blogData, setBlogs] = useState([]);

  useEffect(() => {
    // Function to fetch blogs using Axios
    const fetchBlogs = async () => {
      try {
        const response = await axios.get('https://godigitify-nexus.onrender.com/blogs');
        // Set the fetched blogs to the component state
        setBlogs(response.data);
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    };

    // Call the fetchBlogs function when the component mounts
    fetchBlogs();
  }, []);
  const handleClick = () => {
    // Scroll smoothly to the top of the page
    window.scrollTo({ top: 0, behavior: 'smooth' });

  };
  return (
    <>
      <MegaMenuWithHover />
      <Routes>
        <Route path="/" element={<Home blogs={blogData} />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/events" element={<Events />} />
        <Route path="/blogs" element={<Blogs blogs={blogData} />} />
        <Route path="/sub-blog/:id" element={<SubBlog />} />
        <Route path="/blogs/:blogTitle" element={<BlopPage blogs={blogData} />} />
        <Route path="/careers" element={<CareersHome />} />
        <Route path="/brandingstrategy" element={<Bs />} />
        <Route path="/webdevelopment" element={<Web />} />
        <Route path="/softwaredevelopment" element={<Softdev />} />
        <Route path="/enterprise" element={<EnterpriseSolutions />} />
        <Route path="/cloudsolutions" element={<Cloudsolutions />} />
        <Route path="/graphicdesigning" element={<Graphicdesign />} />
        <Route path="/logodesigning" element={<Logodesign />} />
        <Route path="/digitaldesign" element={<Digitaldesign />} />
        <Route path="/campaignmanagement" element={<Campaignmanagement />} />
        <Route path="/orm" element={<Onlinereputation />} />
        <Route path="/Login" element={<MainLogin />} />
        <Route path="/ecommerce" element={<Ecommerce />} />
        <Route path="/mobiledevelopment" element={<MobileAppdev />} />
        <Route path="/socialmedia" element={<SocialMedia />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/create-blog" element={<Create />} />
        <Route path="/main-admin" element={<Admin blogs={blogData} />} />
        <Route path="/nexus-dbm" element={<Nexus />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path="/terms-conditions" element={<TermsAndConditions />} />
      </Routes>
      <Footer handleClick={handleClick} />
    </>

  );
}

export default App;