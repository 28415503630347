import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../components/LandingPageComponents/keyfeatures.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import { FaHospital } from 'react-icons/fa';
import { BsBriefcase } from 'react-icons/bs';
import { RiStore2Line } from 'react-icons/ri';
import { FiShield } from 'react-icons/fi';
import { MdLocalShipping } from 'react-icons/md';
import { GiLightningStorm } from 'react-icons/gi';
import { FaStethoscope } from 'react-icons/fa';
import { FaIndustry } from 'react-icons/fa';

const keyFeaturesData = [
  {
    icon: <FaHospital />,
    title: 'Healthcare',
    description: 'Embark on a transformative journey in healthcare technology where the focus is on you—your patients, your practice, and your progress.',
  },
  {
    icon: <BsBriefcase />,
    title: 'Banking & Financial',
    description: 'Get commitment to excellence and pioneer innovation in the financial industry where each step is a stride.',
  },
  {
    icon: <RiStore2Line />,
    title: 'Retail',
    description: 'Set your brand apart in the competitive retail landscape and transform your vision into a unique, tech-driven retail experience.',
  },
  {
    icon: <FiShield />,
    title: 'Legal & Compliance',
    description: 'Choose excellence; choose innovation and craft solutions uniquely aligned with the needs of the legal and compliance landscape.',
  },
  {
    icon: <MdLocalShipping />,
    title: 'Supply Chain',
    description: 'Transform your operations and embark on a journey of efficiency with services designed for the dynamic landscape of supply chain industry.',
  },
  {
    icon: <GiLightningStorm />,
    title: 'Energy & Utilities',
    description: 'Embrace innovation in the Energy & Utilities sector with our specialized IT expertise, designed to power up your industry experience.',
  },
  {
    icon: <FaStethoscope />,
    title: 'Medical Devices',
    description: 'Elevate your medical technology that seamlessly integrate into the intricacies of healthcare, ensuring efficiency and patient care.',
  },
  {
    icon: <FaIndustry />,
    title: 'Manufacturing',
    description: 'Experience the power of technology-driven manufacturing transformation, designed to enhance every aspect of your industry operations.',
  },
];

const Industry = () => {
  const sliderRef = useRef(null);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="bg-[#00112d] text-white py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="lg:text-center">
          <p className="mt-2 text-3xl leading-8 font-medium tracking-tight sm:text-4xl">Industires We <span className='text-purple-800'> Cater</span></p>

        </div>
        <div className="mt-10 relative">
          <Slider {...settings}>
            {keyFeaturesData.map((testimonial, index) => (
              <TestimonialCard
                key={index}
                name={testimonial.title}
                position={testimonial.position}
                text={testimonial.description}
                icon={testimonial.icon}
              // orgImage={testimonial.organizationImage}
              />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

const NextArrow = ({ onClick }) => (
  <div className="slick-arrow absolute bottom-0 left-1/2 transform -translate-x-1/2 bg-white p-2 rounded-full shadow-lg cursor-pointer">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="#7F1DFF"
      className="h-6 w-6"
      onClick={onClick}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M9 5l7 7-7 7"
      />
    </svg>
  </div>
);

const PrevArrow = ({ onClick }) => (
  <div className="slick-arrow absolute bottom-0 left-1/2 transform -translate-x-1/2 bg-white p-2 rounded-full shadow-md cursor-pointer">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="#7F1DFF"
      className="h-6 w-6 transform rotate-180"
      onClick={onClick}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M9 5l7 7-7 7"
      />
    </svg>
  </div>
);

const TestimonialCard = ({ name, position, text, icon, orgImage }) => {
  return (
    <Link to="/services">
      <div className="bg-white overflow-hidden shadow rounded-lg">
        <div className="px-4 py-5 sm:p-6 h-[400px] flex flex-col justify-between">
          <div className="flex items-center">
            <div className="flex-shrink-0 text-white bg-purple-800 w-10 h-10 rounded-full text-2xl flex items-center justify-center">
              {icon}
            </div>
            <div className="ml-4">
              <p className="text-xl font-bold text-gray-900">{name}</p>
              <p className="text-sm text-purple-800">{position}</p>
            </div>
          </div>
          <div className="mt-4">
            <p className="text-base text-gray-700">{text}</p>
          </div>
          <div className='flex justify-end'>
            <img src={orgImage} className='right-0 h-[35px]' alt="" srcset="" />
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Industry;
